








































































































































































































































import Vue from "vue";
import _ from "lodash";

const moment = require("moment");

import store from "../../store/shop";
import commonStore from "../../store/common";
import ShopAPI from "../../common/api/api_shop";
import notificationMixin from "../../mixin/notification";
import { Category } from "../../model/entity/category";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import { Menu } from "../../model/entity/menu";
import { isMenuPeriodAvailable } from "../../common/util/checkPeriod";
import * as constant from "../../common/util/constant";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component("Order", {
  components: {
    QButton: () => import("../../components/elements/QButton.vue"),
    SHOrderCardSkeleton: () =>
      import("../../components/components/SHOrderCardSkeleton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QRemainMenuOption: () => import("../../components/elements/QRemainMenuOption.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      categories: null,
      selectedCategory: {},
      tableSessionId: this.$route.params.table_session_id,
      tableSession: {},
      orders: [],
      summary: [],
      newOrder: {},
      modal: {
        menu: { menu_options: undefined },
        order: { quantity: 0, tmp_options: {} },
      },
      isNaizei: "",
      speedList: {},

      isCheck: false,

      errors: "",
      mode: "",
      isLoading: false,
      effects: [],
      langKey: store.state.langKey,
      random: 1,
      interval: null,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    commonStore.dispatch("fetchHoliday");
    this.fetchCategories();
  },
  destroyed() {
    this.interval && clearInterval(this.interval);
  },
  watch: {
    menuChange(newVal, oldVal) {
      console.log('menuChange', newVal);
      if (newVal != '') {
        this.fetchCategories();
      }
    }
  },
  computed: {
    isCategoryNotFound() {
      return _.isEmpty(this.category);
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    categoryId() {
      return this.$route.params.category_id;
    },
    totalQuantity(): number {
      let target = _.find(
        this.summary,
        (summary) => summary.tax_rate == null
      );

      return target.quantity;
    },
    taxType(): string {
        return translate("hall-0096", "税込");
    },
    zeiritsu(): string {
      if (
        this.categoryList[this.newMenu.category_id] === 2 &&
        this.newMenu.is_liquor_tax === false
      ) {
        return (
          " 税率：8%" + " (" + translate("hall-0098", "軽減税率対象商品") + ")"
        );
      } else {
        return " 税率：10%";
      }
    },
    isModalCancel(): boolean {
      return this.mode === "cancel";
    },
    isModalMenu(): boolean {
      return this.mode === "menu";
    },
    isModalOption(): boolean {
      return this.mode === "option";
    },
    isEffect() {
      // check prefix id
      return /mecop_/.test(this.categoryId);
    },
    category(): null | Category {
      if (this.categories) {
        if (this.isEffect) {
          let category = null;
          _.every(this.effects, (effect) => {
            const cat = _.find(
              effect.category_option.categories,
              (cat) => cat.menus_category_option_id === this.categoryId
            );
            if (cat) {
              category = cat;
              return false;
            }
            return true;
          });
          return category;
        } else {
          return _.find(this.categories, (cat) => cat.id === this.categoryId);
        }
      } else {
        return null;
      }
    },
    menuNameOfCategoryOption: () => (category) => {
      return _.map(category.menus, (menu) => menu.name).join("・");
    },
    menus() {
      this.random;
      let menus = this.category.menus;
      if (menus) {
        const currentTime = moment();
        return menus.filter((item) => this.isMenuAvaible(item, currentTime));
      }
      return [];
    },
    menuChange() {
      return store.state.pageChange.menu;
    },
    remain: function() {
      return this.modal.menu.remain_order > 0 ? this.modal.menu.remain_order : 0
    }
  },
  methods: {
    intervalCheckAvaiable() {
      const self = this;
      this.interval = setInterval(function () {
        self.random = Date.now();
      }, constant.TIME_RE_COMPUTED);
      // 30 * 60
    },
    isMenuAvaible(menu: Menu, currentTime): boolean {
      // return item.menu_options.;
      return isMenuPeriodAvailable(menu, currentTime);
    },
    // Header
    setTableSession() {
      if (!this.tableSession) {
        return;
      }
      store.commit("setTableSession", this.tableSession);
    },

    // カテゴリータブ
    isSelectedCategories(category) {
      return this.selectedCategory.id === category.id;
    },
    categoryType(category) {
      const rtn = {
        1: "eatin",
        2: "takeout",
        3: "catering",
      };
      return rtn[category.type];
    },
    // オプション入力
    handleClickMenu: function (menu) {
      if (this.isLoading) {
        return;
      }

      this.mode = "option";
      this.modal.menu = menu;
      const orderIndex = this.getTargetOrderIndex(menu);
      this.modal.order = _.cloneDeep(this.orders[orderIndex]);
      if (!this.modal.order.quantity) {
        this.modal.order.quantity = 1;
      }
      if (!this.modal.order.tmp_options.length) {
        this.modal.order.tmp_options = [];
      }
    },
    handleClickIncrease: function (menu) {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(menu, +1);
    },
    handleClickDecrease: function (menu) {
      if (this.isLoading) {
        return;
      }

      this.updateQuantity(menu, -1);
    },
    handleClickSubmitTmpOrder: function () {
      if (this.isLoading) {
        return;
      }
      this.mode = "";
      let message = "";
      this.isLoading = true;
      const orderIndex = this.getTargetOrderIndex(this.modal.menu);
      api
        .order_index({ order: this.modal.order })
        .then((response) => {
          this.orders.push(response.data.order);
          this.summary = response.data.summary;
          message = response.data.message;
          // this.showSuccessNotification(message); // ODR-963
          this.isLoading = false;

          return;
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.showErrorNotification(err.response.data.message);
          this.isLoading = false;
        });
    },
    optionValueCheckbox(option_menu) {
      const isExist = _.findIndex(
        this.modal.order.tmp_options,
        function (row: any) {
          return (
            option_menu.menu_option_id === row.menus_menu_option.menu_option_id
          );
        }
      );
      if (isExist === -1) {
        if (option_menu.default_option === true) {
          this.modal.order.tmp_options.push({
            menus_menu_option_id: option_menu.id,
            menus_menu_option: {
              menu_option_id: option_menu.menu_option_id,
            },
            quantity: this.modal.order.quantity,
          });
        }
        return option_menu.default_option;
      }
      const result = _.findIndex(
        this.modal.order.tmp_options,
        function (row: any) {
          return option_menu.id === row.menus_menu_option_id;
        }
      );
      return result !== -1;
    },
    optionChangeRadio(event, option) {
      _.remove(this.modal.order.tmp_options, function (row: any) {
        if (!row.menus_menu_option) {
          return false;
        }
        return option.id === row.menus_menu_option.menu_option_id;
      });
      this.modal.order.tmp_options.push({
        menus_menu_option_id: _.clone(event.target.value),
        menus_menu_option: {
          menu_option_id: option.id,
        },
        quantity: this.modal.order.quantity,
      });
    },
    optionChangeCheckbox(event, option) {
      if (event.target.checked) {
        this.modal.order.tmp_options.push({
          menus_menu_option_id: _.clone(event.target.value),
          menus_menu_option: {
            menu_option_id: option.id,
          },
          quantity: this.modal.order.quantity,
        });
      } else {
        const tmp_options = _.cloneDeep(this.modal.order.tmp_options);
        _.remove(tmp_options, function (row: any) {
          if (!row.menus_menu_option) {
            return false;
          }
          return event.target.value === row.menus_menu_option_id;
        });
        this.modal.order.tmp_options = tmp_options;
      }
    },
    getTargetOrderIndex: function (menu) {
      for (let index = 0; index < this.orders.length; index++) {
        const order = this.orders[index];
        if (order.menu_id === menu.id) {
          return index;
        }
      }
      const newItem = _.cloneDeep(this.newOrder);
      newItem.menu = menu;
      newItem.menu_id = menu.id;
      newItem.tmp_options = [];
      this.orders.push(newItem);

      return this.getTargetOrderIndex(menu);
    },
    updateQuantity: function (menu, offset) {
      const quantity = Math.max(1, this.modal.order.quantity + offset);
      this.modal.order.quantity = quantity;
    },

    // キャンセル
    handleClickCancel: function () {
      this.mode = "cancel";
    },
    handleClickReturn: function () {
      this.mode = "menu";
    },
    handleClickCloseEditor: function () {
      this.closeEditor();
    },
    closeEditor: function () {
      this.mode = "";
      this.isLoading = false;
      this.isCheck = false;
    },
    getStatus: function (item) {
      if (this.isCheck === false) {
        return "";
      }
      if (!item) {
        return "error";
      }
      return "";
    },
    fetchCategories() {
      this.isLoading = true;
      let message = "";
      api
        .order_home({ table_session_id: this.tableSessionId })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0048", "データの取得に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.isCheck = false;
          this.categories = response.data.data.categories;
          this.speedList = response.data.data.speedList;
          this.tableSession = response.data.data.tableSession;
          this.orders = response.data.data.orders;
          this.newOrder = response.data.data.newOrder;
          this.isNaizei = response.data.data.tableSession.shop.is_naizei;
          this.effects = response.data.data.effects;
          this.summary = response.data.data.summary;
          this.setTableSession();
          store.commit("setSelectedCategory", this.category);
          this.intervalCheckAvaiable();
        })
        .catch((err) => {
          this.isLoading = false;
          message =
            err.response.data.message ||
            translate("hall-0048", "データの取得に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    isValid() {
      return true;
      const optionIds = this.modal.order.tmp_options || [];
      const summary = _.countBy(this.modal.menu.menu_options, (menu_option) => {
        const allOption = _.map(
          menu_option.menus_menu_options,
          (opt) => opt.id
        );
        const selectCount =
          _.countBy(optionIds, (value) => {
            return _.includes(allOption, value.menus_menu_option_id);
          }).true || 0;
        const min = menu_option.min;
        const max = menu_option.max;

        return min <= selectCount && selectCount <= max;
      });

      const falseCount = summary.false || 0;

      return falseCount == 0;
    },
  },
  filters: {
    taxPrice: function (prices, flg) {
      if (flg == true) {
        return prices["include"];
      }
      if (flg == false) {
        return prices["exclude"];
      }
      return;
    },
    numberFormat: function (number) {
      if (typeof number !== "number") {
        return number;
      }
      return number.toLocaleString();
    },
    isStatus(menu) {
      if (menu.is_soldout === true) {
        return "on";
      }
      if (menu.is_set_maximum_order_per_day === true && menu.remain_order === 0) {
        return "on";
      }
      return '';
    },
  },
});
